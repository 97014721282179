const initialState = {
    usuario: null,
    mensaje: ""
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "SET_CURRENT_USER":
            return {
                ...state,
                usuario: action.usuario,
                mensaje: action.mensaje
            };
        default:
            return state;
    }
}
