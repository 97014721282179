export const API_SERVER_PROTOCOL = process.env.REACT_APP_API_SERVER_PROTOCOL;
export const API_SERVER_DOMAIN = process.env.REACT_APP_API_SERVER_DOMAIN;
export const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
export const API_SERVER_URL_BASE = API_SERVER_PROTOCOL + API_SERVER_DOMAIN;
export const API_VERSION = "/v1"
// export const IS_INTERNAL_ACCESS = window.location.hostname.includes('.com') || window.location.hostname === 'localhost' ? false : true;
// export const API_SERVER_ENDPOINT_BASE = IS_INTERNAL_ACCESS ? 'http://localhost:4000' : API_SERVER_URL_BASE + API_VERSION;
export const API_SERVER_ENDPOINT_BASE = API_SERVER_URL_BASE + API_VERSION;
export const REDUX_STORE_NAME = process.env.REACT_APP_API_REDUX_STORE_NAME
export const AUTH_TOKEN_KEY_NAME = process.env.REACT_APP_AUTH_TOKEN_KEY_NAME;
export const DARA_WEB_APP_VERSION = process.env.REACT_APP_WEB_APP_VERSION;
export const POSMASTER = process.env.REACT_APP_API_SERVER_POSMASTER;
export const API_KEY_GOOGLE_MAPS = process.env.REACT_APP_API_KEY_GOOGLE_MAPS;

export const Endpoint = (endpoint) => {
    return API_SERVER_ENDPOINT_BASE + endpoint;
}

export const PrivateHeaders = () => new Headers({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY_NAME)}`
});

export const PrivateHeadersAxios = () => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY_NAME)}`
});

export const PrivateFileHeaders = () => new Headers({
    'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY_NAME)}`
});

export const PublicHeaders = () => new Headers({
    'Content-Type': 'application/json'
});

export const PrivateFileWithToken = (url) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY_NAME) || '';
    const completeURL = url ? `${url}?AuthToken=${token}` : "";
    return completeURL;
}

export const EndpointUsuarios = (endpoint) => {
    return 'https://' + process.env.REACT_APP_API_SERVER_USUARIOS + API_VERSION + endpoint;
}