import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import {REDUX_STORE_NAME, AUTH_TOKEN_KEY_NAME} from '../../config'

const PrivateRoute = ({component : Component, ...rest}) => {
     const isAuth = (props) => {
        // Validamos que esté autenticado
        if(localStorage.getItem(AUTH_TOKEN_KEY_NAME) === null){   
            return false;
        }

        // Tomamos el state del local storage pues no tenemos acceso al estado antes de que sea renderado
        if(localStorage.getItem(REDUX_STORE_NAME) === null){   
            return false;
        }

        var currentStore = JSON.parse(localStorage.getItem(REDUX_STORE_NAME));
        if(currentStore.Login.user === null){
            return false;
        }

        // Aqui debe se debe validar si el usuario 
        // tiene acceso a la url asincronicamente

        return true;
    }
    
    return (
        <Route
            {...rest}
            render={props => isAuth(props) ? (
                <Component {...props} />
            ) : (
                <Redirect to={
                    {
                        pathname : '/pages/login',
                        state : {message : 'Usuario no autorizado'}
                    }
                } />
            )}
        />
    );
}

export default PrivateRoute;