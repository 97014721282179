
export const logout = (props) => {
    return dispatch => {
        return dispatch({
            type: "SET_CURRENT_USER",
            usuario: null
        })
    }
}

export default { logout }
