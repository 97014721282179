import React, { Suspense, lazy, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from 'react-loaders'
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './auth';
import { logout } from '../../store/actions/';
import IdleTimer from 'react-idle-timer';
import { AUTH_TOKEN_KEY_NAME, REDUX_STORE_NAME } from '../../config';
import ValidarPermiso from '../../ValidarPermiso'

const Dashboards = lazy(() => import('../../Pages/Dashboards'));
const Applications = lazy(() => import('../../Pages/Applications'));
const Gestion = lazy(() => import('../../Pages/Gestion'));
const Bodegas = lazy(() => import('../../Pages/Bodegas'));
const UserPages = lazy(() => import('../../Pages/UserPages'));
const Cierres = lazy(() => import('../../Pages/Cierres'));
const Auditorias = lazy(() => import('../../Pages/Auditorias'));
const RinoTickets = lazy(() => import('../../Pages/RinoTickets'));
const Precios = lazy(() => import('../../Pages/Precios'));
const Reportes = lazy(() => import('../../Pages/Reportes'));
const CajaChica = lazy(() => import('../../Pages/CajaChica'));
const CajaChica2 = lazy(() => import('../../Pages/CajaChica2'))
const Cupones = lazy(() => import('../../Pages/Cupones'));
const TarjetasClub = lazy(() => import('../../Pages/TarjetasClub'));
const Logistica = lazy(() => import('../../Pages/Logistica'));
const Test = lazy(() => import('../../Pages/Test'));
const PedidosLubricantes = lazy(() => import('../../Pages/PedidosLubricantes'));
const Pagos = lazy(() => import('../../Pages/Pagos'));
const Vuelve = lazy(() => import('../../Pages/Vuelve'));
const Formularios = lazy(() => import('../../Pages/Formularios'));
const Documentos = lazy(() => import('../../Pages/Documentos'));
const FEL = lazy(() => import('../../Pages/FEL'));
const Desabastecimientos = lazy(() => import('../../Pages/Desabastecimientos'));
const SaldosClientes = lazy(() => import('../../Pages/SaldosClientes'));
const SeguimientosUnidades = lazy(() => import('../../Pages/SeguimientosUnidades'));
const Alertas = lazy(() => import('../../Pages/Alertas'));
const BusinessInteligence = lazy(() => import('../../Pages/BusinessInteligence'));
const Suministradas = lazy(() => import('../../Pages/Suministradas'));
const ReportesDinamicos = lazy(() => import('../../Pages/reportesDinamicos'));
const DashboardSeguimientos = lazy(() => import('../../Pages/dashboardSeguimientos'));
const Sap = lazy(() => import('../../Pages/SAP'));
const Mercadeo = lazy(() => import('../../Pages/Mercadeo'));
const RinoBillete = lazy(() => import('../../Pages/RinoBilletes')  )
const Platts = lazy(() => import('../../Pages/Platts'))
const Actas = lazy(()=> import('../../Pages/Actas'))


class AppMain extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            timeout: 1000 * 60 * 30, //timeout de 30 minutos
            timeout2: 1000 * 60 * 1440, //timeout de  24 horas
        }
        this.idleTimer = null
        this.onIdle = this._onIdle.bind(this)
    }

    _onIdle(e) {
        this.props.logout(this.props)
    }

    render() {
        return (
            <Fragment>

                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={ValidarPermiso(this.props.usuario, "Sesion_Activa", 1) ? this.state.timeout2 : this.state.timeout} />

                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/pages" component={UserPages} />
                </Suspense>

                {/* Dashboards */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/dashboards" component={Dashboards} />
                </Suspense>

                {/* Applications */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/apps" component={Applications} />
                </Suspense>

                {/* Gestión */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/gestion" component={Gestion} />
                </Suspense>

                {/* Cierres */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/cierres" component={Cierres} />
                </Suspense>

                {/* Auditorias */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/auditorias" component={Auditorias} />
                </Suspense>
                
                {/*Actas */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/actas" component={Actas} />
                </Suspense>

                {/* RinoTickets */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/tickets" component={RinoTickets} />
                </Suspense>

                {/* Precios */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/precios" component={Precios} />
                </Suspense>

                {/* Logistica */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/logistica" component={Logistica} />
                </Suspense>

                {/* Reportes */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/reportes" component={Reportes} />
                </Suspense>

                {/* Reportes Dinámicos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/reportes-dinamicos"
                        component={ReportesDinamicos}
                    />
                </Suspense>

                {/* Caja Chica */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/caja-chica" component={CajaChica} />
                </Suspense>

                {/* Caja Chica 2.0 */}
                <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader />
                        </div>
                        <h6 className="mt-3">
                            Por favor espere...
                            <small>Se estan cargando los componentes de la aplicación</small>
                        </h6>
                    </div>
                </div>
                }>
                    <PrivateRoute path="/caja-chica-2" component={CajaChica2} />
                </Suspense>
                {/* Cupones */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/cupones" component={Cupones} />
                </Suspense>

                {/* Tarjetas club */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/tarjetas-club" component={TarjetasClub} />
                </Suspense>

                {/* test */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/test" component={Test} />
                </Suspense>

                {/* fel */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/fel" component={FEL} />
                </Suspense>

                <Route exact path="/" render={() => (
                    <Redirect to="/dashboards/crm" />
                )} />



                {/* Bodegas */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/bodegas" component={Bodegas} />
                </Suspense>

                {/* Pedidos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/pedidos-lubricantes" component={PedidosLubricantes} />
                </Suspense>

                {/* Pagos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/pagos" component={Pagos} />
                </Suspense>


                {/* Pagos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/vuelve" component={Vuelve} />
                </Suspense>

                {/* Formularios */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/formularios" component={Formularios} />
                </Suspense>

                {/* Documentos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/documentos" component={Documentos} />
                </Suspense>

                {/* Desabastecimientos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/desabastecimientos" component={Desabastecimientos} />
                </Suspense>

                {/* SaldosClientes */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/saldos-clientes" component={SaldosClientes} />
                </Suspense>

                {/* SeguimientosUnidades */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/seguimientos-unidades" component={SeguimientosUnidades} />
                </Suspense>

                {/* Alertas */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/alertas" component={Alertas} />
                </Suspense>

                {/* BusinessInteligence */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/business-intelligence" component={BusinessInteligence} />
                </Suspense>


                {/* Suministradas */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/suministradas" component={Suministradas} />
                </Suspense>

                {/* DashboardSeguimientos */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/dashboard-seguimientos" component={DashboardSeguimientos} />
                </Suspense>
                
                {/* Mercadeo */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/mercadeo" component={Mercadeo} />
                </Suspense>

                 {/* SAP */}
                 <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/sap" component={Sap} />
                </Suspense>

                {/* RINO BILLETE  */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader />
                            </div>
                            <h6 className="mt-3">
                                Por favor espere...
                                <small>Se estan cargando los componentes de la aplicación</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute path="/rino-billete" component={RinoBillete} />
                </Suspense>


                {/* Caja Chica 2.0 */}
                <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader />
                        </div>
                        <h6 className="mt-3">
                            Por favor espere...
                            <small>Se estan cargando los componentes de la aplicación</small>
                        </h6>
                    </div>
                </div>
                }>
                    <PrivateRoute path="/platts" component={Platts} />
                </Suspense>

                <ToastContainer />

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    usuario: state.Login.usuario,
    auth: state.auth
});

const mapDispatchToProps = dispatch => {
    return {
        logout(props) {
            localStorage.removeItem(AUTH_TOKEN_KEY_NAME);
            localStorage.removeItem(REDUX_STORE_NAME);
            dispatch(logout(props))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
