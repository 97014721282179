import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Store from './store/store';
import { REDUX_STORE_NAME } from './config'

import './assets/base.scss';
import 'react-pivottable/pivottable.css';

import Main from './Pages/Main';


const rootElement = document.getElementById('root');
var peristedState;
const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STORE_NAME);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return {};
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(REDUX_STORE_NAME, serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

peristedState = loadState();
const store = Store(peristedState);
store.subscribe(() => {
  saveState(store.getState());
});

const renderApp = Component => {
  ReactDOM.render(<Provider store={store}><HashRouter><Component /></HashRouter></Provider>, rootElement);
};

renderApp(Main);
if (module.hot) {
  module.hot.accept('./Pages/Main', () => {
    const NextApp = require('./Pages/Main').default;
    renderApp(NextApp);
  });
}

